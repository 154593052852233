import bundleConstructor from "./bundle";

const plugin = (opts) => {
  return {
    bundle: bundleConstructor(opts),
    components: [],
  };
};

export default plugin;

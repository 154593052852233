import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import { transform } from "ol/proj";
import throttle from "../../utils/throttle";

export default connect("selectMap", ({ map }) => {
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const handlePointerMove = throttle((e) => {
    const view = map.getView();
    const proj = view.getProjection().getCode();
    const coord = e.coordinate;
    const projected = transform(coord, proj, "EPSG:4326");
    setX(Math.round(projected[0] * 10000) / 10000);
    setY(Math.round(projected[1] * 10000) / 10000);
  }, 50);

  useEffect(() => {
    if (!map) return undefined;
    map.on("pointermove", handlePointerMove);
    return () => {
      map.un("pointermove", handlePointerMove);
    };
  }, [map]);

  let formattedX = x;
  let formattedY = y;
  if (String.prototype.padStart) {
    formattedX = x.toString().padStart(9, " ");
    formattedY = y.toString().padStart(8, " ");
  }

  return (
    <span
      style={{
        padding: "7px",
        fontSize: "0.8em",
        fontFamily: "Consolas, monaco, monospace",
        whiteSpace: "pre",
      }}
      className="text-light bg-primary"
    >{`${formattedX} ${formattedY}`}</span>
  );
});

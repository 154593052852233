import React, { useState } from "react";
import Select from "./select";
import Radio from "./radio";
import Checkbox from "./checkbox";

export default ({ title }) => {
  const [len, setLen] = useState("M/KM");
  const [lenRad, setLenRad] = useState("FT / MI");
  const [use, setUse] = useState(false);
  return (
    <div>
      <strong>{title}</strong>
      <Select
        id="length"
        label="Length Units"
        options={["FT / MI", "M/KM"]}
        onChange={(update) => {
          setLen(update.length);
        }}
        value={len}
      />
      <Radio
        id="length-radio"
        label="Length Units"
        options={["FT / MI", "M/KM"]}
        onChange={(update) => {
          console.log(update);
          setLenRad(update["length-radio"]);
        }}
        value={lenRad}
      />
      <Checkbox
        id="use-metric"
        label="Include the thing"
        onChange={(update) => {
          console.log(update);
          setUse(update["use-metric"]);
        }}
        value={use}
      />
      <hr />
    </div>
  );
};

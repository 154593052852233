import { createSelector } from "redux-bundler";

export default {
  name: "plugins",

  getReducer: () => {
    const initialData = {};

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "PLUGINS_REGISTERED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doPluginsRegister: (plugin) => ({ dispatch, store }) => {
    const allComponents = store.selectPluginsComponents();
    if (plugin.components && plugin.components.length) {
      plugin.components.forEach((component) => {
        if (!allComponents.hasOwnProperty(component.region)) {
          allComponents[component.region] = [];
        }
        allComponents[component.region].push(component);
      });
    }
    dispatch({
      type: "PLUGINS_REGISTERED",
      payload: allComponents,
    });
  },

  selectPluginsState: (state) => {
    return state.plugins;
  },

  selectPluginsFlags: createSelector("selectPluginsState", (state) => {
    const flags = {};
    Object.keys(state).forEach((key) => {
      if (key[0] === "_") flags[key] = state[key];
    });
    return flags;
  }),

  selectPluginsComponents: createSelector("selectPluginsState", (state) => {
    const items = {};
    Object.keys(state).forEach((key) => {
      if (key[0] !== "_") items[key] = state[key];
    });
    return items;
  }),
};

import React from "react";
import TreeViewFilter from "./tree-view-filter";
import TreeListContainer from "./tree-list-container";

export default () => {
  return (
    <div className="card-body" style={{ padding: "5px" }}>
      <TreeViewFilter />
      <TreeListContainer />
    </div>
  );
};

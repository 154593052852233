import React from "react";

export default ({ id, label, value, options, onChange }) => {
  const handleChange = (e) => {
    if (onChange && typeof onChange === "function") {
      if (e.target.checked) onChange({ [id]: e.target.value });
    }
  };
  return (
    <div className="form-group">
      <label>{label}</label>
      {options.map((opt, i) => {
        return (
          <div key={`${id}-${i}`} className="form-check">
            <label className="form-check-label">
              <input
                type="radio"
                className="form-check-input"
                name={`${id}-radios`}
                value={opt}
                checked={value === opt}
                onChange={handleChange}
              />
              {opt}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default (store, plugins) => {
  // make sure we even get plugins
  if (plugins && plugins.length) {
    plugins.forEach((p) => {
      // add any bundles that come along with the plugin
      if (p.bundle) {
        // we provide a single bundle or an array of bundles, either way, make it an array here
        if (!p.bundle.length) p.bundle = [p.bundle];
        p.bundle.forEach((b) => {
          store.integrateBundles(b);
        });
      }

      // add any UI components that come along with the plugin
      if (p.hasOwnProperty("components")) {
        store.doPluginsRegister(p);
      }
    });
  }
};

import CoordinateDisplaySpan from "./coordinate-display-span";

const plugin = {
  components: [
    {
      component: CoordinateDisplaySpan,
      region: "map-tools-bottom-left",
      sortOrder: 10,
    },
  ],
};

export default plugin;

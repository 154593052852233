import React from "react";
import TreeListItemDataset from "./tree-list-item-dataset";
import TreeListItemFolder from "./tree-list-item-folder";

export default ({ item }) => {
  const isFolder = !item.mapLayer;
  const ItemComp = isFolder ? TreeListItemFolder : TreeListItemDataset;
  if (!item.show) return null;
  return (
    <li style={{ listStyle: "none" }}>
      <ItemComp item={item} />
    </li>
  );
};

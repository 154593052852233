import React from "react";
import { connect } from "redux-bundler-react";
import PanelGroup from "./panelgroup/PanelGroup";
import Panel from "./panels/Panel";
import North from "./panels/North";
import South from "./panels/South";
import East from "./panels/East";
import West from "./panels/West";
import Map from "./Map";
import VerticalToolbar from "./Vertical-Toolbar";
import debounce from "lodash.debounce";

export default connect(
  "selectMap",
  "selectPluginsComponents",
  "selectWestPanelSize",
  "selectEastPanelSize",
  "selectNorthPanelSize",
  "selectSouthPanelSize",
  "selectWestPanelIsOpen",
  "selectEastPanelIsOpen",
  "selectNorthPanelIsOpen",
  "selectSouthPanelIsOpen",
  "doWestPanelSetSize",
  "doEastPanelSetSize",
  "doNorthPanelSetSize",
  "doSouthPanelSetSize",
  ({
    map,
    pluginsComponents,
    westPanelSize,
    eastPanelSize,
    northPanelSize,
    southPanelSize,
    westPanelIsOpen,
    eastPanelIsOpen,
    northPanelIsOpen,
    southPanelIsOpen,
    doWestPanelSetSize,
    doEastPanelSetSize,
    doNorthPanelSetSize,
    doSouthPanelSetSize,
    controls,
    interactions,
    layers,
    overlays,
    view,
    epsg,
  }) => {
    const updateEastWestSize = debounce((s) => {
      if (westPanelIsOpen) doWestPanelSetSize(s[0].size);
      if (eastPanelIsOpen) doEastPanelSetSize(s[2].size);
    }, 200);

    const updateNorthSouthSize = debounce((s) => {
      if (northPanelIsOpen) doNorthPanelSetSize(s[0].size);
      if (southPanelIsOpen) doSouthPanelSetSize(s[2].size);
    });

    const updateMapSize = debounce(() => {
      if (map) map.updateSize();
    }, 200);

    const eastWestConfig = [
      {
        size: westPanelIsOpen ? westPanelSize : 0,
        minSize: 100,
        resize: westPanelIsOpen ? "dynamic" : "fixed",
      },
      { minSize: 100, resize: "stretch" },
      {
        size: eastPanelIsOpen ? eastPanelSize : 0,
        minSize: 100,
        resize: eastPanelIsOpen ? "dynamic" : "fixed",
      },
    ];

    const northSouthConfig = [
      {
        size: northPanelIsOpen ? northPanelSize : 0,
        minSize: 100,
        resize: northPanelIsOpen ? "dynamic" : "fixed",
      },
      { minSize: 100, resize: "stretch" },
      {
        size: southPanelIsOpen ? southPanelSize : 0,
        minSize: 100,
        resize: southPanelIsOpen ? "dynamic" : "fixed",
      },
    ];

    // loop through the plugins that are available and
    // set our sidebar widths to 40 if buttons exist, otherwise
    // set them to 0
    let leftSidebarWidth = 0;
    let rightSidebarWidth = 0;
    let mapToolsTopHeight = 0;
    let mapToolsBottomHeight = 0;
    Object.keys(pluginsComponents).forEach((key) => {
      if (key.indexOf("left-sidebar") !== -1) leftSidebarWidth = 40;
      if (key.indexOf("right-sidebar") !== -1) rightSidebarWidth = 40;
      if (key.indexOf("map-tools-top") !== -1) mapToolsTopHeight = 40;
      if (key.indexOf("map-tools-bottom") !== -1) mapToolsBottomHeight = 40;
    });

    return (
      <div
        style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}
      >
        <PanelGroup
          spacing={0}
          panelWidths={[
            { size: leftSidebarWidth, resize: "fixed" },
            { resize: "stretch" },
            { size: rightSidebarWidth, resize: "fixed" },
          ]}
        >
          <VerticalToolbar region="left-sidebar" />
          <PanelGroup
            onUpdate={(e) => {
              updateEastWestSize(e);
              updateMapSize();
            }}
            spacing={1}
            panelWidths={eastWestConfig}
          >
            <West />
            <PanelGroup
              onUpdate={(e) => {
                updateNorthSouthSize(e);
                updateMapSize();
              }}
              spacing={1}
              direction="column"
              panelWidths={northSouthConfig}
            >
              <North />
              <Panel>
                <Map
                  controls={controls}
                  interactions={interactions}
                  layers={layers}
                  overlays={overlays}
                  view={view}
                  epsg={epsg}
                  mapToolsTopHeight={mapToolsTopHeight}
                  mapToolsBottomHeight={mapToolsBottomHeight}
                />
              </Panel>
              <South />
            </PanelGroup>
            <East />
          </PanelGroup>
          <VerticalToolbar region="right-sidebar" />
        </PanelGroup>
      </div>
    );
  }
);

import Button from "./button";
// import bundle from "./bundle";

const plugin = {
  bundle: null,
  components: [
    {
      component: Button,
      region: "map-tools-top-left",
      sortOrder: 10,
    },
  ],
};

export default plugin;

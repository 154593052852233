import React from "react";

export default ({ id, label, value, onChange }) => {
  const handleChange = (e) => {
    if (onChange && typeof onChange === "function") {
      onChange({ [id]: e.target.checked });
    }
  };
  return (
    <div className="form-group">
      <div className="form-check">
        <label className="form-check-label">
          <input
            type="checkbox"
            className="form-check-input"
            name={`${id}-check`}
            value={id}
            checked={value}
            onChange={handleChange}
          />
          {label}
        </label>
      </div>
    </div>
  );
};

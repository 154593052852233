import React, { useState, useEffect } from "react";
import classnames from "../../utils/classnames";
import TreeList from "./tree-list";

export default ({ item }) => {
  const [collapsed, setCollapsed] = useState(item.collapsed);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    item.collapsed = collapsed;
  }, [collapsed]);

  const iconClass = classnames({
    mdi: true,
    "mdi-chevron-down": !collapsed,
    "mdi-chevron-right": collapsed,
    "text-primary": true,
  });

  return (
    <div>
      <div
        className="overflow-ellipsis"
        style={{ width: "100%", overflow: "hidden" }}
      >
        <i
          title="Click to collapse or expand"
          onClick={toggleCollapsed}
          className={iconClass}
          style={{ cursor: "pointer" }}
        ></i>
        <span
          onDoubleClick={toggleCollapsed}
          style={{ userSelect: "none", paddingLeft: "8px" }}
        >
          {`${item.displayName} (${item.children.length})`}
        </span>
      </div>
      <TreeList items={item.children} collapsed={collapsed} />
    </div>
  );
};

import React from "react";
import OptionsGroup from "./option-group";

export default () => {
  return (
    <div className="card-body">
      <strong>Measure Tools</strong>
      <OptionsGroup />
    </div>
  );
};

import React from "react";
import classnames from "../utils/classnames";

export default ({ active, children, iconClass, className, onClick, title }) => {
  const incomingClassObj = className
    ? className.split(" ").reduce((out, key) => {
        out[key] = true;
      }, {})
    : {};
  const btnClass = classnames({
    ...{
      btn: true,
      "btn-sm": true,
      "btn-link": !active,
      "btn-primary": active,
    },
    ...incomingClassObj,
  });
  if (iconClass) {
    children = [<i key={1} className={iconClass}></i>];
  }
  return (
    <button
      onClick={onClick}
      style={{ width: "40px", height: "40px" }}
      className={btnClass}
      title={title}
    >
      {children}
    </button>
  );
};

import React from "react";
import { connect } from "redux-bundler-react";
import ToolbarButton from "../../components/ToolbarButton";
import TreeViewPanel from "./tree-view-panel-new";

export default connect(
  "doWestPanelOpen",
  "doWestPanelClose",
  "selectWestPanelContents",
  ({ doWestPanelOpen, doWestPanelClose, westPanelContents }) => {
    const active = westPanelContents === TreeViewPanel;
    const handleClick = () => {
      if (active) {
        doWestPanelClose();
      } else {
        doWestPanelOpen(TreeViewPanel);
      }
    };
    return (
      <ToolbarButton
        active={active}
        iconClass="ms ms-layers"
        onClick={handleClick}
      />
    );
  }
);

import { defaults } from "ol/interaction";

export default {
  name: "interactions",

  getReducer: () => {
    const initialData = {
      defaults: defaults(),
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "INTERACTIONS_ADDED":
        case "INTERACTIONS_ADDED_DEFAULTS":
        case "INTERACTIONS_REMOVED":
        case "INTERACTIONS_REMOVED_ALL":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doInteractionsAdd: (interaction, idx = null) => ({ dispatch, store }) => {
    const map = store.selectMap();
    if (idx !== null) {
      const interactions = map.getInteractions();
      interactions.setAt(idx, interaction);
    } else {
      map.addInteraction(interaction);
    }
    dispatch({
      type: "INTERACTIONS_ADDED",
    });
  },

  doInteractionsAddDefaults: () => ({ dispatch, store }) => {
    const map = store.selectMap();
    const interactions = store.selectInteractionsDefaults();
    interactions.forEach((interaction) => {
      map.addInteraction(interaction);
    });
    dispatch({
      type: "INTERACTIONS_ADDED_DEFAULTS",
    });
  },

  doInteractionsRemove: (interaction) => ({ dispatch, store }) => {
    const map = store.selectMap();
    map.addInteraction(interaction);
    dispatch({
      type: "INTERACTIONS_REMOVED",
    });
  },

  doInteractionsRemoveAll: () => ({ dispatch, store }) => {
    const map = store.selectMap();
    const interactions = map.getInteractions();
    interactions.forEach((interaction) => {
      map.removeInteraction(interaction);
    });
    dispatch({
      type: "INTERACTIONS_REMOVED_ALL",
    });
  },

  selectInteractionsDefaults: (state) => {
    return state.interactions.defaults;
  },
};

import React, { useState, useEffect } from "react";
import { connect } from "redux-bundler-react";
import debounce from "../../utils/debounce";

export default connect("doLayersFilter", ({ doLayersFilter }) => {
  const [filterString, setFilterString] = useState("");

  const setFilter = debounce(doLayersFilter, 100);

  useEffect(() => {
    setFilter(filterString);
  }, [filterString]);

  return (
    <div className="input-group">
      <input
        className="form-control form-control-sm"
        type="text"
        value={filterString}
        onChange={(e) => {
          setFilterString(e.target.value);
        }}
        onKeyUp={(e) => {
          if (e.keyCode === 27) setFilterString("");
        }}
      ></input>
      <div className="input-group-append">
        <button
          disabled={!filterString}
          onClick={() => {
            setFilterString("");
          }}
          className="btn btn-sm btn-outline-secondary"
        >
          <i className="mdi mdi-close"></i>
        </button>
      </div>
    </div>
  );
});

import CoordinateDisplaySpan from "./coordinate-display-span";
import bundle from "./bundle";

const plugin = {
  bundle: bundle,
  components: [
    {
      component: CoordinateDisplaySpan,
      region: "map-tools-bottom-left",
      sortOrder: 10,
    },
  ],
};

export default plugin;

import React from "react";
import PluginRegion from "./PluginRegion";

export default ({ region }) => {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{ position: "absolute", top: 0 }}
        className="btn-group-vertical justify-content-start align-items-start"
        data-toggle="buttons"
      >
        <PluginRegion region={`${region}-top`} />
      </div>
      <div
        style={{ position: "absolute", bottom: 0 }}
        className="btn-group-vertical justify-content-end align-items-end"
        data-toggle="buttons"
      >
        <PluginRegion region={`${region}-bottom`} />
      </div>
    </div>
  );
};

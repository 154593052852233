import { ScaleLine } from "ol/control";

export default (overrides) => {
  return {
    name: "scalebar",
    getReducer: () => {
      const initialData = {
        _shouldInit: false,
      };
      return (state = initialData, { type, payload }) => {
        switch (type) {
          case "SCALEBAR_INITIALIZE_START":
            return Object.assign({}, state, payload);
          case "MAP_INITIALIZED":
            return Object.assign({}, state, { _shouldInit: true });
          default:
            return state;
        }
      };
    },
    doScalebarInit:
      (target) =>
      ({ dispatch, store }) => {
        dispatch({
          type: "SCALEBAR_INITIALIZE_START",
          payload: {
            _shouldInit: false,
          },
        });
        const map = store.selectMap();
        const defaults = {
          target: target,
          units: "us",
        };
        const options = Object.assign({}, defaults, overrides);
        const scale = new ScaleLine(options);
        map.addControl(scale);
      },
    reactScalebarShouldInit: (state) => {
      if (state.scalebar._shouldInit)
        return { actionCreator: "doScalebarInit" };
    },
  };
};

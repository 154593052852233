import React from "react";
import { useEffect, useState } from "react";
import { Provider } from "redux-bundler-react";
import getStore from "../bundles";
import cache from "../utils/cache";
import loadPlugins from "../utils/load-plugins";
import pkg from "../../package.json";
import App from "./App.js";

import "../css/bootstrap/css/bootstrap.cm4.min.css";
import "../css/mdi/materialdesignicons.min.css";
import "../css/mapskin/mapskin.min.css";
import "../css/index.css";

/**
 * CorpsMap can be used as a component in your app!
 *
 * Props:
 * plugins - array of plugin objects
 *
 */

export default (props) => {
  let [store, setStore] = useState(null);

  useEffect(() => {
    if (store) return undefined;
    const createStore = async () => {
      await cache.getAll().then((initialData) => {
        const store = getStore(initialData);
        loadPlugins(store, props.plugins);
        setStore(store);
      });
    };
    createStore();
  }, [store, cache]);

  window.store = store;
  if (!store) return null;
  return (
    <Provider store={store}>
      <App {...props} />
    </Provider>
  );
};

import React, { useRef, useEffect } from "react";
import { connect } from "redux-bundler-react";
import MapToolsOverlay from "./MapToolsOverlay";

import "ol/ol.css";

export default connect(
  "doMapInitialize",
  "selectMap",
  "selectInteractionsDefaults",
  ({
    doMapInitialize,
    map,
    interactionsDefaults,
    interactions = interactionsDefaults,
    controls,
    layers,
    overlays,
    view,
    epsg,
    mapToolsTopHeight,
    mapToolsBottomHeight,
  }) => {
    const mapEl = useRef();

    useEffect(() => {
      if (!mapEl.current) return undefined;
      if (map) return undefined;
      doMapInitialize({
        target: mapEl.current,
        controls,
        interactions,
        layers,
        overlays,
        view,
        epsg,
      });
    }, [mapEl.current, map]);

    return (
      <div
        style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}
        ref={mapEl}
      >
        <MapToolsOverlay
          top
          region="map-tools-top"
          height={mapToolsTopHeight}
        />
        <MapToolsOverlay
          bottom
          region="map-tools-bottom"
          height={mapToolsBottomHeight}
        />
      </div>
    );
  }
);

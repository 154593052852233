import React from "react";
import { connect } from "redux-bundler-react";

export default connect(
  "selectPluginsComponents",
  ({ pluginsComponents, region, style }) => {
    if (!pluginsComponents.hasOwnProperty(region)) return null;
    const components = pluginsComponents[region].sort((a, b) => {
      if (!a.hasOwnProperty("sortOrder") || !b.hasOwnProperty("sortOrder"))
        return 0;
      if (a.sortOrder > b.sortOrder) return 1;
      if (a.sortOrder < b.sortOrder) return -1;
      return 0;
    });
    if (!components.length) return null;
    return (
      <div style={style}>
        {components.map((c, i) => {
          const Comp = c.component;
          return <Comp key={i} />;
        })}
      </div>
    );
  }
);

import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { fromLonLat } from "ol/proj";
import { defaults } from "ol/control";

export default {
  name: "map",

  getReducer: () => {
    const initialData = {
      map: null,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "MAP_INITIALIZED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doMapInitialize: ({
    target,
    interactions,
    controls = defaults({
      attribution: false,
      zoom: false,
    }),
    layers = [
      new TileLayer({
        source: new XYZ({
          url:
            "https://cartodb-basemaps-c.global.ssl.fastly.net/dark_all//{z}/{x}/{y}@2x.png",
        }),
      }),
    ],
    overlays,
    view = new View({
      center: fromLonLat([-96, 39]),
      zoom: 4,
      maxZoom: 18,
    }),
  }) => ({ dispatch }) => {
    const map = new Map({
      target,
      controls,
      interactions,
      layers,
      overlays,
      view,
    });
    window.map = map;
    dispatch({
      type: "MAP_INITIALIZED",
      payload: {
        map: map,
      },
    });
  },

  selectMap: (state) => {
    return state.map.map;
  },
};

import React from "react";
import PluginRegion from "./PluginRegion";

export default ({ top, bottom, region, height }) => {
  const style = {
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: 999,
  };
  if (top) style.top = "5px";
  if (bottom) style.bottom = "5px";
  return (
    <div style={style}>
      <div
        style={{
          height: height,
        }}
      >
        <div
          style={{ position: "absolute", left: "5px" }}
          className="btn-group justify-content-start align-items-center"
          data-toggle="buttons"
        >
          <PluginRegion region={`${region}-left`} />
        </div>
        <div
          style={{ position: "absolute", right: "5px" }}
          className="btn-group justify-content-end align-items-center"
          data-toggle="buttons"
        >
          <PluginRegion region={`${region}-right`} />
        </div>
      </div>
    </div>
  );
  return (
    <div style={style}>
      <div
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        }}
      >
        <div>
          <PluginRegion style={{ padding: "5px" }} region={region} />
        </div>
      </div>
    </div>
  );
};

import React from "react";
import TreeListItem from "./tree-list-item";

export default ({ items, collapsed, listStyle }) => {
  if (!!collapsed) return null;
  return (
    <ul className="cm-tree-list" style={listStyle}>
      {items.map((item) => {
        return (
          <TreeListItem
            key={item.id}
            item={item}
            collapsed={item.collapsed}
            time={new Date()}
          />
        );
      })}
    </ul>
  );
};

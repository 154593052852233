import React from "react";
import { connect } from "redux-bundler-react";
import ToolbarButton from "../../components/ToolbarButton";
import OptionsPanel from "./options-panel";

export default connect(
  "doEastPanelOpen",
  "doEastPanelClose",
  "selectEastPanelContents",
  ({ doEastPanelOpen, doEastPanelClose, eastPanelContents }) => {
    const active = eastPanelContents === OptionsPanel;
    const handleClick = () => {
      if (active) {
        doEastPanelClose();
      } else {
        doEastPanelOpen(OptionsPanel);
      }
    };
    return (
      <ToolbarButton
        active={active}
        iconClass="mdi mdi-cog"
        onClick={handleClick}
      />
    );
  }
);

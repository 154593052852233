import React, { useState, useEffect } from "react";

export default ({ item }) => {
  const [visible, setVisible] = useState(item.mapLayer.getVisible());
  const [legend, setLegend] = useState(false);

  useEffect(() => {
    if (!item.mapLayer) return undefined;
    item.mapLayer.setVisible(visible);
  }, [visible]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "space-between",
        }}
      >
        <label
          style={{
            display: "inline-block",
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            marginRight: "15px",
            zIndex: 99,
          }}
          className="cm-checkbox-inline cm-checkbox-ellipsis"
          title={item.displayName}
        >
          <input
            className="mr-2"
            type="checkbox"
            checked={visible}
            onChange={(e) => {
              setVisible(!visible);
            }}
          />
          {item.displayName}
        </label>
        {!!item.legend ? (
          <span
            title="Show legend"
            style={{ zIndex: 999 }}
            onClick={() => {
              setLegend(!!legend ? null : item.legend);
            }}
            className="pointer"
          >
            <i className="mdi mdi-format-list-bulleted-type text-muted" />
          </span>
        ) : null}
        {!!item.details ? (
          <span
            title="Open Details Panel"
            style={{ zIndex: 999 }}
            onClick={() => {
              console.log("open details");
            }}
            className="pointer"
          >
            <i className="mdi mdi-more text-muted" />
          </span>
        ) : null}
        {!!item.info ? (
          <span
            title="More Information"
            style={{ zIndex: 999 }}
            onClick={() => {
              console.log("open more information");
            }}
            className="pointer"
          >
            <i className="mdi mdi-alert-circle-outline  text-muted"></i>
          </span>
        ) : null}
      </div>
      <div>{!!legend ? legend : null}</div>
    </div>
  );
};

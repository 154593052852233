import { fromLonLat } from "ol/proj";
import proj4 from "proj4";
import { get as getProjection, getTransform } from "ol/proj";
import { applyTransform } from "ol/extent";
import { register } from "ol/proj/proj4";
import { View } from "ol";

function getViewFromProjection({
  epsg,
  def,
  centerLonLat,
  bbox,
  zoom = 4,
  minZoom = 0,
  maxZoom = 18,
  minResolution,
  maxResolution,
  constrainRotation = false,
  enableRotation = true,
  extent,
}) {
  if (!epsg || epsg.indexOf("EPSG:") !== 0)
    throw new Error('EPSG code must be in the form "EPSG:..."');
  proj4.defs(epsg, def);
  register(proj4);
  var newProjection = getProjection(epsg);
  var fromLonLat2 = getTransform("EPSG:4326", newProjection);

  if (bbox) {
    let worldExtent = [bbox[1], bbox[2], bbox[3], bbox[0]];
    if (bbox[1] > bbox[3]) {
      worldExtent = [bbox[1], bbox[2], bbox[3] + 360, bbox[0]];
    }
    const extent = applyTransform(worldExtent, fromLonLat2, undefined, 8);
    newProjection.setExtent(extent);
  }

  const newView = new View({
    projection: newProjection,
    zoom,
    minZoom,
    maxZoom,
    minResolution,
    maxResolution,
    constrainRotation,
    enableRotation,
    extent,
  });

  if (centerLonLat) {
    const center = fromLonLat(centerLonLat, epsg);
    newView.setCenter(center);
  }

  return newView;
}

export default getViewFromProjection;

import bundle from "./bundle";
import TreeviewButton from "./tree-view-button";

const plugin = {
  bundle: bundle,
  components: [
    {
      component: TreeviewButton,
      region: "left-sidebar-top",
      sortOrder: 12,
    },
  ],
};

export default plugin;

import React from "react";
import { connect } from "redux-bundler-react";
import Panel from "./Panel";

export default connect(
  "selectNorthPanelContents",
  ({ northPanelContents: contents }) => {
    if (!contents) return null;
    if (!Array.isArray(contents)) contents = [contents];
    return (
      <Panel>
        {contents.map((Comp, i) => {
          return <Comp key={i} />;
        })}
      </Panel>
    );
  }
);

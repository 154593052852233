import React from "react";
import TreeList from "./tree-list";
import { connect } from "redux-bundler-react";

export default connect("selectLayersRoot", ({ layersRoot }) => {
  const items = layersRoot.children || [];
  return (
    <div>
      <TreeList
        items={items}
        isFolder={true}
        collapsed={false}
        listStyle={{ padding: "5px", marginTop: "1em" }}
      />
    </div>
  );
});

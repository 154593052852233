import React from "react";

export default ({ id, value, label, options, onChange }) => {
  const handleChange = (e) => {
    if (onChange && typeof onChange === "function") {
      onChange({ [id]: e.target.value });
    }
  };
  return (
    <div className="form-group">
      <label>{label}</label>
      <select
        value={value}
        onChange={handleChange}
        className="form-control form-control-sm"
      >
        {options.map((opt, i) => {
          return (
            <option key={`${id}-${i}`} value={opt}>
              {opt}
            </option>
          );
        })}
      </select>
    </div>
  );
};

import { composeBundles, createCacheBundle } from "redux-bundler";
import cache from "../utils/cache";
import createPanelBundle from "./create-panel-bundle";
import exclusiveInteractionBundle from "./exclusive-interaction-bundle";
import interactionsBundle from "./interactions-bundle";
import layersBundle from "./layers-bundle/layers-bundle";
import mapBundle from "./map-bundle";
import pluginBundle from "./plugin-bundle";

export default composeBundles(
  createCacheBundle({
    cacheFn: cache.set,
  }),
  createPanelBundle({
    name: "northPanel",
  }),
  createPanelBundle({
    name: "southPanel",
  }),
  createPanelBundle({
    name: "eastPanel",
  }),
  createPanelBundle({
    name: "westPanel",
  }),
  exclusiveInteractionBundle,
  interactionsBundle,
  layersBundle,
  mapBundle,
  pluginBundle
);
